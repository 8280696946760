import React from "react";
import { useState, useEffect, useMemo } from 'react';
import { Popover } from '@material-ui/core';
import SwissArmyButton from '../SwissArmyButton';
import { gray } from '../../../definitions/colors';
import { ArrowDown } from '../../icons';
import { merge } from 'lodash';

// The default styles largely mimic the style of the MUI button was previously used in this component
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const defaultStyle = {
  default: {
    color: gray[200],
    textColor: 'black',
    fontWeight: 500,
    border: {
      radius: 5
    },
    dropShadow: {
      color: gray[500],
      blurRadius: '5px',
      offsetX: '0px',
      offsetY: '2px'
    }
  },
  hover: {
    color: gray[300],
    textColor: 'black',
    fontWeight: 500,
    border: {
      radius: 5
    },
    dropShadow: {
      color: gray[600],
      blurRadius: '5px',
      offsetX: '0px',
      offsetY: '2px'
    }
  },
  pressed: {
    color: gray[300],
    fontWeight: 500,
    textColor: 'black',
    border: {
      radius: 5
    },
    dropShadow: {
      color: gray[600],
      blurRadius: '7px',
      offsetX: '0px',
      offsetY: '4px'
    }
  },
  disabled: {
    color: gray[100],
    textColor: gray[300],
    fontWeight: 500,
    dropShadow: {
      color: gray[300],
      blurRadius: '5px',
      offsetX: '0px',
      offsetY: '2px'
    }
  },
  icon: {
    fontSize: '1em'
  }
};
/**
 * Renders a button that display `children` in a popover widget.
 */
export default function PopoverButton(props) {
  const {
    children,
    buttonDisplayContent,
    onClose,
    setIsPopoverOpen,
    isDisabled = false,
    styleOverrides = {}
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const finalStyle = useMemo(() => merge({}, defaultStyle, styleOverrides), [styleOverrides]);
  const onCloseHandler = () => {
    setAnchorEl(null);
    onClose && onClose();
  };
  useEffect(() => {
    if (!setIsPopoverOpen) return;
    if (anchorEl) {
      setIsPopoverOpen(true);
    } else {
      setIsPopoverOpen(false);
    }
  }, [anchorEl, setIsPopoverOpen]);
  const menu = _jsx(Popover, {
    id: "dropdown",
    "aria-expanded": !!anchorEl,
    open: Boolean(anchorEl),
    onClose: onCloseHandler,
    anchorEl: anchorEl,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    keepMounted: true,
    children: children
  });
  const button = _jsx(SwissArmyButton, {
    text: buttonDisplayContent,
    textTransform: "none",
    onPress: event => setAnchorEl(event.currentTarget),
    disabled: isDisabled,
    styleSpec: finalStyle,
    icon: ArrowDown,
    iconPosition: "right",
    additionalAriaProperties: {
      'aria-controls': 'dropdown',
      'aria-haspopup': 'true',
      type: 'button'
    }
  });
  return _jsxs("div", {
    style: {
      width: 'fit-content',
      ...(isDisabled ? {
        cursor: 'not-allowed'
      } : {})
    },
    onClick: event => {
      // prevent click event from propagating to ancestor nodes
      event.stopPropagation();
    },
    children: [button, menu]
  });
}