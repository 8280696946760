import React from 'react';
import TableSearch from './TableSearch';
import RowCounter from './RowCounter';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
class TableToolbar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderSearch = this.renderSearch.bind(this);
    this.renderCounter = this.renderCounter.bind(this);
    this.renderChildren = this.renderChildren.bind(this);
    this.renderAddRemoveColumns = this.renderAddRemoveColumns.bind(this);
  }
  renderTitle() {
    const {
      options
    } = this.props;
    const {
      title
    } = options;
    if (!title) return null;
    return _jsx("h1", {
      className: "TableToolbar-Title",
      children: title
    });
  }
  renderSearch() {
    const {
      uiState,
      eventHandlers
    } = this.props;
    const {
      onSearch
    } = eventHandlers;
    const {
      searchQuery
    } = uiState;
    if (!onSearch) return null;
    return _jsx(TableSearch, {
      query: searchQuery,
      onSearch: onSearch
    });
  }
  renderCounter() {
    const {
      rows = {},
      options = {},
      uiState = {},
      eventHandlers
    } = this.props;
    const {
      showCount,
      toolbar
    } = options;
    if (!showCount || !toolbar) return null;
    const props = {
      rows,
      uiState,
      eventHandlers
    };
    return _jsx("div", {
      className: "TableToolbar-Info",
      children: _jsx(RowCounter, {
        ...props
      })
    });
  }
  renderChildren() {
    const {
      children
    } = this.props;
    if (!children) return null;
    return _jsx("div", {
      className: "TableToolbar-Children",
      children: children
    });
  }
  renderAddRemoveColumns() {
    return null;
  }
  render() {
    const Title = this.renderTitle;
    const Search = this.renderSearch;
    const Counter = this.renderCounter;
    const Children = this.renderChildren;
    return _jsxs("div", {
      className: "Toolbar TableToolbar",
      children: [_jsx(Title, {}), _jsx(Search, {}), _jsx(Children, {}), _jsx(Counter, {})]
    });
  }
}
export default TableToolbar;