import React from 'react';
import { isLeaf } from '../../SelectTree/Utils';
import IndeterminateCheckbox from '../IndeterminateCheckbox';
import { ArrowRight, ArrowDown } from '../../../icons';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const defaultTreeNodeStyleSpec = {
  list: {
    listStyle: 'none'
  },
  children: {
    padding: '0 0 0 1.5em',
    margin: 0
  },
  nodeWrapper: {
    display: 'flex',
    alignItems: 'start',
    padding: '1px 0'
  },
  topLevelNodeWrapper: {},
  leafNodeLabel: {
    display: 'flex',
    width: '100%',
    marginLeft: '1.25em',
    alignItems: 'start'
  },
  nodeLabel: {
    display: 'flex',
    width: '100%',
    marginLeft: '0.5em',
    alignItems: 'start'
  },
  labelTextWrapper: {
    width: '100%',
    margin: 'auto 0',
    paddingLeft: '0.25em'
  }
};
function TreeRadio(_ref) {
  let {
    name,
    checked,
    value,
    node,
    onChange
  } = _ref;
  const handleClick = () => {
    if (!checked) {
      onChange(node, false);
    }
  };
  return _jsx("input", {
    type: "radio",
    name: name,
    value: value,
    checked: checked,
    onChange: handleClick
  });
}
export default function CheckboxTreeNode(_ref2) {
  let {
    name,
    node,
    path,
    getNodeState,
    isSelectable,
    isMultiPick,
    isActiveSearch,
    toggleSelection,
    toggleExpansion,
    getNodeId,
    getNodeChildren,
    renderNode,
    customCheckboxes,
    shouldExpandOnClick,
    isTopLevelNode = false
  } = _ref2;
  let {
    isSelected,
    isIndeterminate,
    isVisible,
    isExpanded
  } = getNodeState(node);
  let isLeafNode = isLeaf(node, getNodeChildren);
  let inputName = isLeafNode ? name : '';
  let nodeId = getNodeId(node);
  const nodeElement = renderNode(node, path.split('/').map(Number));
  const commonInputProps = {
    name: inputName,
    checked: isSelected,
    node,
    value: nodeId
  };
  const checkboxProps = {
    ...commonInputProps,
    indeterminate: !!isIndeterminate,
    onChange: isChecked => toggleSelection(node, isChecked)
  };
  const CustomCheckbox = customCheckboxes && nodeId in customCheckboxes ? customCheckboxes[nodeId] : undefined;
  return _jsxs("li", {
    className: `list ${isVisible ? 'visible-element' : 'hidden-element'}`,
    children: [_jsxs("div", {
      className: isTopLevelNode ? 'top-level-node-wrapper' : 'node-wrapper',
      children: [isLeafNode ? null : isActiveSearch ?
      // this retains the space of the expansion toggle icons for easier formatting
      _jsx("div", {
        className: "active-search-buffer"
      }) : isExpanded ? _jsx("div", {
        className: "arrow-container",
        children: _jsx(ArrowDown, {
          className: "arrow-icon",
          tabIndex: 0,
          onClick: e => {
            e.stopPropagation();
            toggleExpansion(node);
          },
          onKeyDown: e => e.key === 'Enter' ? toggleExpansion(node) : null
        })
      }) : _jsx("div", {
        className: "arrow-container",
        children: _jsx(ArrowRight, {
          className: "arrow-icon",
          tabIndex: 0,
          onClick: e => {
            e.stopPropagation();
            toggleExpansion(node);
          },
          onKeyDown: e => e.key === 'Enter' ? toggleExpansion(node) : null
        })
      }), !isSelectable || !isMultiPick && !isLeafNode ? _jsx("div", {
        className: "label-text-wrapper",
        onClick: shouldExpandOnClick ? () => toggleExpansion(node) : undefined,
        children: nodeElement
      }) : _jsxs("label", {
        className: isLeafNode ? 'leaf-node-label' : 'node-label',
        children: [CustomCheckbox ? _jsx(CustomCheckbox, {
          ...checkboxProps
        }) : isMultiPick ? _jsx(IndeterminateCheckbox, {
          ...checkboxProps
        }) : _jsx(TreeRadio, {
          ...commonInputProps,
          onChange: toggleSelection
        }), _jsx("div", {
          className: "label-text-wrapper",
          children: nodeElement
        })]
      })]
    }), !isLeafNode && isVisible && isExpanded && _jsx("ul", {
      className: `children ${isExpanded ? 'visible-element' : 'hidden-element'}`,
      children: getNodeChildren(node).map((child, index) => _jsx(CheckboxTreeNode, {
        name: name,
        node: child,
        path: path + '/' + index,
        getNodeState: getNodeState,
        isSelectable: isSelectable,
        isMultiPick: isMultiPick,
        isActiveSearch: isActiveSearch,
        toggleSelection: toggleSelection,
        toggleExpansion: toggleExpansion,
        shouldExpandOnClick: shouldExpandOnClick,
        getNodeId: getNodeId,
        getNodeChildren: getNodeChildren,
        renderNode: renderNode,
        customCheckboxes: customCheckboxes
      }, 'node_' + getNodeId(child)))
    })]
  });
}